/**
 * Drop Cups
 */

.text-dropcup,
.text-dropcup-sq {
  display: inline-block;
  margin-right: 3px;
}

.text-dropcup {
  font-size: 2.2em;
  font-weight: 600;
}

.text-dropcup-sq {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1em;
  border-radius: 4px;
  padding: 2px 6px 3px;
  width: 1.25em;
  text-align: center;
}