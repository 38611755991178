/**
 * Editor - wp specific
 */

/* Alignments */
.alignleft {
  display: inline;
  float: left;
}

.alignright {
  display: inline;
  float: right;
}

.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

blockquote.alignleft,
.wp-caption.alignleft,
img.alignleft {
  margin: 0.4em 1.6em 1.6em 0;
}

blockquote.alignright,
.wp-caption.alignright,
img.alignright {
  margin: 0.4em 0 1.6em 1.6em;
}

blockquote.aligncenter,
.wp-caption.aligncenter,
img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em;
}

.wp-caption.alignleft,
.wp-caption.alignright,
.wp-caption.aligncenter {
  margin-bottom: 1.2em;
}

/* Caption */
.wp-caption {
  background: transparent;
  border: none;
  color: inherit;
  font-family: "Noto Sans", sans-serif;
  margin: 0 0 28px 0;
  max-width: 100%;
  padding: 0;
  text-align: inherit;
  opacity: 0.75;
}

.wp-caption.alignleft {
  margin: 7px 28px 21px 0;
}

.wp-caption.alignright {
  margin: 7px 0 21px 28px;
}

.wp-caption.aligncenter {
  margin: 7px auto;
}

.wp-caption .wp-caption-text,
.wp-caption-dd {
  color: inherit;
  font-size: 13px;
  line-height: 1.1;
  padding: 10px 0;
  text-align: center;
  opacity: 0.75;
}

/* Galleries */
.gallery {
  margin-bottom: 1.6em;
}

.gallery-item {
  display: inline-block;
  padding: 1.79104477%;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.gallery-caption {
  color: inherit;
  opacity: 0.75;
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  line-height: 1.5;
  padding: 0.5em 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

/* Figure */
.editor {
  figure {
    margin: $base-space * 2 0;
  }
}