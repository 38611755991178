/**
 * Avatars
 */

.avatar {
  border-radius: 50%;
}

.avatar-42 {
  width: 42px;
  height: 42px;
}

.avatar-195 {
  width: 195px;
  height: 195px;
}