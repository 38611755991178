@import "../../mixins/clearfix";
@import "../../mixins/grid";
@import "../../mixins/grid-framework";

/**
 * Grid
 */

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row;
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// small smartphones.

@include make-grid(xxs);


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@media (min-width: $screen-xs) {
  @include make-grid(xs);
}


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}
