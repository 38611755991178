/**
 * Footer
 */

#crtFooter {
  clear: both;
  padding-top: 20px;
  padding-bottom: 20px;
}

#crtBtnUp {
	font-size: 22px;
	right: 20px;
	bottom: 20px;
	position: fixed;
	width: 50px;
	height: 50px;
    display: none;
}