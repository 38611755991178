/**
 * Buttons
 */

.btn,
input[type="submit"] {
  font-family: inherit;
  font-size: $btn-font-size;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 8px 25px;
  display: inline-block;
  border-radius: $btn-border-radius;
  background: none;
  border: solid 2px transparent;
  cursor: pointer;
  -webkit-appearance: none;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0;
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:hover {
    box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  }
}

.btn {
  .crt-icon {
    font-size: 1.2em;
    line-height: 1em;
    vertical-align: top;
    display: inline-block;
    margin-right: 0.8em;
  }
}

// Button Sizes
.btn-lg {
  font-size: $btn-lg-font-size;
  padding: 12px 35px;
}

.btn-sm {
  font-size: $btn-sm-font-size;
  padding: 6px 20px;
}

// Button Options
.btn-thin {
  font-weight: 400;
}

.btn-upper {
  text-transform: uppercase;
}

.btn-block {
  display: block;
  width: 100%;
  margin: 0 auto;
}


// Disabled Buttons
.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  opacity: .65;

  &:hover {
    box-shadow: none;
  }
}

// Icon Buttons
.btn-icon {
  width: 63px;
  height: 63px;
  text-align: center;
  padding: 0;
  border-radius: 50%;

  .crt-icon {
    font-size: $btn-icon-font-size;
    margin: 0;
  }
}

// Shadow
.btn-shade {
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.25);
  }
}

// Buttons Group
.btn-group {
  display: block;
  margin-left: -5px;
  margin-right: -5px;

  .btn {
    margin: 5px;
  }

  .btn-block {
    margin: 5px auto;
  }
}